import React from 'react';


export default function NotFound404() {
  return (
    <div className="not-found">
      Sorry!
      <small>This page could not be found</small>
    </div>
  )
}